.container {
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.content {
  padding-top: 32px;
  padding-bottom: 68px;
  padding-left: 37px;
  padding-right: 37px;
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #1A0D31;
  margin-top: 40px;
  font-family: 'Mulish';
  text-align: center;
}

.inputContainer {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: #1A0D31;
  font-family: 'Mulish';
}

.input {
  width: 100%;
  margin-top: 4px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  height: 40px;
  padding: 10px 14px;
}

.input:focus {
  outline: none;
  border: 1px solid #6E4C8E
;
}

.input::placeholder {
    color:#918F95ed;
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 400;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  border-radius: 8px;
  margin-top: 40px;
}